import React from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, Card, Space } from 'antd';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import apiClient from '../../lib/api/apiClient';

const { Meta } = Card;

const CardMeta = styled.div`
  .ant-card-meta {
    display: flex;
  }

  .ant-card-meta-avatar {
    padding-inline-end: 16px;
  }

  .ant-card-meta-title {
    color: #ffff;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  .ant-card-meta-description {
    color: #ffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 130px;
  }
`;

interface LayoutMetaProps {
  collapsed: boolean;
  title: string;
  description: string;
  icon: React.ReactElement;
}

function LayoutMeta({ collapsed, title, description, icon }: LayoutMetaProps) {
  return (
    <Space
      style={{
        height: 100,
        padding: 16,
        display: 'flex',
        justifyContent: collapsed ? 'center' : 'flex-start',
        alignContent: 'center',
      }}
    >
      {!collapsed && (
        <CardMeta>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: '#87d068' }}
                shape="square"
                icon={icon}
              />
            }
            title={title}
            description={description}
          />
        </CardMeta>
      )}
      {collapsed && <Avatar shape="square" icon={icon} />}
    </Space>
  );
}

interface OfficeLayoutMetaProps {
  collapsed: boolean;
}

function OfficeLayoutMeta({ collapsed }: OfficeLayoutMetaProps) {
  const { organizationId = '', userId = '' } = useParams();

  const { data: userData } = useQuery(
    ['user', userId],
    async () => {
      if (userId === '') {
        return null;
      }
      return (await apiClient.get(`/2.0/office/users/${userId}`)) as {
        data: {
          displayName: string;
          email: string;
        };
      };
    },
    {
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: organizationData } = useQuery(
    ['user', organizationId],
    async () => {
      if (organizationId === '') {
        return null;
      }
      return (await apiClient.get(
        `/2.0/office/organizations/${organizationId}`,
      )) as { data: { displayName: string } };
    },
    {
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  if (userId === '' && organizationId === '') {
    return null;
  }

  if (userId !== '') {
    return (
      <LayoutMeta
        collapsed={collapsed}
        title={userData?.data.displayName || ''}
        description={userData?.data.email || ''}
        icon={<UserOutlined />}
      />
    );
  }

  return (
    <LayoutMeta
      collapsed={collapsed}
      title="그룹"
      description={organizationData?.data.displayName || ''}
      icon={<TeamOutlined />}
    />
  );
}

export default OfficeLayoutMeta;
