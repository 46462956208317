import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  AuditOutlined,
  CreditCardOutlined,
  IdcardOutlined,
  LineChartOutlined,
  MailOutlined,
  MobileOutlined,
  ReadOutlined,
  ScanOutlined,
  SettingOutlined,
  ShoppingOutlined,
  SyncOutlined,
  TeamOutlined,
  UserOutlined,
  WalletOutlined,
  FlagOutlined,
  BarcodeOutlined,
} from '@ant-design/icons';
import { Layout, Menu, MenuProps, Space } from 'antd';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import React, { Suspense, useMemo, useState } from 'react';
import OfficeLayoutMeta from './OfficeLayoutMeta';
import OfficeContentLoading from '../OfficeContentLoading';
import OfficeLayoutProfile from './OfficeLayoutProfile';

const { Content, Sider } = Layout;

const getOrganizationMenus: any = (organizationId: string) => {
  return {
    back: {
      key: 'back',
      label: '목록으로',
      url: '/organizations',
      icon: <ArrowLeftOutlined />,
    },
    users: {
      key: '그룹 회원',
      label: '그룹 회원',
      url: `/organizations/${organizationId}/users`,
      icon: <UserOutlined />,
    },
    allowedEmails: {
      key: '가입가능 이메일',
      label: '가입가능 이메일',
      url: `/organizations/${organizationId}/allowed-emails`,
      icon: <MailOutlined />,
    },
    paymentProduct: {
      key: '구매',
      label: '구매',
      url: `/organizations/${organizationId}/purchases`,
      icon: <ScanOutlined />,
    },
    subscriptions: {
      key: '구독',
      label: '구독',
      url: `/organizations/${organizationId}/subscriptions`,
      icon: <SyncOutlined />,
    },
    credits: {
      key: '크레딧',
      label: '크레딧',
      url: `/organizations/${organizationId}/credits`,
      icon: <CreditCardOutlined />,
    },
    statistics: {
      key: '통계',
      label: '통계',
      url: `/organizations/${organizationId}/statistics`,
      icon: <LineChartOutlined />,
    },
  };
};

const getUserMenus: any = (userId: string) => {
  return {
    back: {
      key: 'back',
      label: '목록으로',
      url: '/users',
      icon: <ArrowLeftOutlined />,
    },
    details: {
      key: '회원 정보',
      label: '회원 정보',
      url: `/users/${userId}/details`,
      icon: <UserOutlined />,
    },
    organizations: {
      key: '가입된 그룹',
      label: '가입된 그룹',
      url: `/users/${userId}/organizations`,
      icon: <TeamOutlined />,
    },
    paymentProduct: {
      key: '구매',
      label: '구매',
      url: `/users/${userId}/purchases`,
      icon: <ScanOutlined />,
    },
    subscriptions: {
      key: '구독',
      label: '구독',
      url: `/users/${userId}/subscriptions`,
      icon: <SyncOutlined />,
    },
    note: {
      key: '자막',
      label: '자막',
      url: `/users/${userId}/notes`,
      icon: <ReadOutlined />,
    },
    appVersion: {
      key: '크레딧',
      label: '크레딧',
      url: `/users/${userId}/credits`,
      icon: <CreditCardOutlined />,
    },
    transcription: {
      key: '통역',
      label: '통역',
      url: `/users/${userId}/transcriptions`,
      icon: <AuditOutlined />,
    },
    statistics: {
      key: '통계',
      label: '통계',
      url: `/users/${userId}/statistics`,
      icon: <LineChartOutlined />,
    },
  };
};

const mainMenus = {
  user: {
    key: '회원',
    label: '회원',
    icon: <IdcardOutlined />,
    url: '',
  },
  allowedEmail: {
    key: '가입가능 이메일',
    label: '가입가능 이메일',
    url: '/allowed-emails',
    icon: <MailOutlined />,
  },
  inviteCode: {
    key: '초대 코드',
    label: '초대 코드',
    url: '/invite-codes',
    icon: <BarcodeOutlined />,
  },
  userManage: {
    key: '회원 관리',
    label: '회원 관리',
    url: '/users',
    icon: <UserOutlined />,
  },
  organization: {
    key: '그룹',
    label: '그룹',
    url: '/organizations',
    icon: <TeamOutlined />,
  },
  product: {
    key: '상품',
    label: '상품',
    icon: <ShoppingOutlined />,
    url: '',
  },
  paymentProduct: {
    key: '요금제',
    label: '요금제',
    url: '/products',
    icon: <WalletOutlined />,
  },
  appManage: {
    key: '앱',
    label: '앱',
    icon: <MobileOutlined />,
    url: '',
  },
  appVersion: {
    key: '앱 버전',
    label: '앱 버전',
    url: '/app-versions',
    icon: <AppstoreOutlined />,
  },
  banner: {
    key: '배너',
    label: '배너',
    url: '',
    icon: <FlagOutlined />,
  },
  mobileBanner: {
    key: 'mobileBanner',
    label: '모바일',
    url: '/banners?platform=mobile&type=all&activated=all',
    icon: <FlagOutlined />,
  },
  webBanner: {
    key: 'webBanner',
    label: '웹',
    url: '/banners?platform=web&type=all&activated=all',
    icon: <FlagOutlined />,
  },
  manage: {
    key: '관리',
    label: '관리',
    icon: <SettingOutlined />,
    url: '',
  },
  manager: {
    key: '관리자',
    label: '관리자',
    url: '/managers',
    icon: <SettingOutlined />,
  },
};

const mainMenuItems = [
  {
    ...mainMenus.user,
    children: [
      mainMenus.allowedEmail,
      mainMenus.inviteCode,
      mainMenus.userManage,
      mainMenus.organization,
    ],
  },
  {
    ...mainMenus.product,
    children: [mainMenus.paymentProduct],
  },
  {
    ...mainMenus.appManage,
    children: [mainMenus.appVersion],
  },
  {
    ...mainMenus.banner,
    children: [mainMenus.mobileBanner, mainMenus.webBanner],
  },
  {
    ...mainMenus.manage,
    children: [mainMenus.manager],
  },
];

const defaultOpenKeys = mainMenuItems.map((mainMenuitem) => mainMenuitem.key);

function OfficeLayout() {
  const navigate = useNavigate();
  let location = useLocation();
  let [searchParams] = useSearchParams();

  const { organizationId = '', userId = '' } = useParams();

  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);

  const menus = useMemo(() => {
    if (organizationId !== '') {
      return getOrganizationMenus(organizationId);
    } else if (userId !== '') {
      return getUserMenus(userId);
    }

    return mainMenus;
  }, [organizationId, userId]);

  const menuArray: { key: string; url: string; pathname: string }[] =
    Object.values(menus);

  const items = useMemo(() => {
    if (organizationId !== '') {
      return [
        menus.back,
        {
          key: 'group',
          type: 'group',
          children: [
            menus.users,
            menus.allowedEmails,
            menus.paymentProduct,
            menus.subscriptions,
            menus.note,
            menus.credits,
            menus.statistics,
          ],
        },
      ];
    } else if (userId !== '') {
      return [
        menus.back,
        {
          key: 'group',
          type: 'group',
          children: [
            menus.details,
            menus.organizations,
            menus.paymentProduct,
            menus.subscriptions,
            menus.note,
            menus.appVersion,
            menus.transcription,
            menus.statistics,
          ],
        },
      ];
    }

    return mainMenuItems;
  }, [menus, organizationId, userId]);

  const onSelect = (data: any) => {
    navigate(data.item.props.url);
  };

  const selectedKeys = useMemo(() => {
    if (
      location.pathname === '/banners' &&
      searchParams.get('platform') === 'mobile'
    ) {
      return ['mobileBanner'];
    } else if (
      location.pathname === '/banners' &&
      searchParams.get('platform') === 'web'
    ) {
      return ['webBanner'];
    }

    const selectedMenu = menuArray.find(
      (menu) => menu.url === location.pathname,
    );
    return selectedMenu ? [selectedMenu.key] : [];
  }, [location, menuArray, searchParams]);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    setOpenKeys(keys);
  };

  const onCollapse = (value: boolean) => {
    if (value) {
      setOpenKeys([]);
    }

    setCollapsed(value);
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <Space
          style={{
            height: 64,
            padding: 16,
          }}
        >
          <OfficeLayoutProfile collapsed={collapsed} />
        </Space>
        <OfficeLayoutMeta collapsed={collapsed} />
        <Menu
          theme="dark"
          openKeys={openKeys}
          mode="inline"
          items={items}
          onSelect={onSelect}
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Suspense fallback={<OfficeContentLoading />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}

export default OfficeLayout;
