import React from 'react';
import { Avatar, Dropdown, MenuProps, Typography } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authState } from '../../atoms/authState';
import { config } from '../../config';
import { LogoutOutlined } from '@ant-design/icons';
import { themeState } from '../../atoms/themeState';

const { Text: AntdText } = Typography;

interface OfficeLayoutProfileProps {
  collapsed: boolean;
}

function OfficeLayoutProfile({ collapsed }: OfficeLayoutProfileProps) {
  const [auth, setAuth] = useRecoilState(authState);
  const setTheme = useSetRecoilState(themeState);

  const handleThemeClick: MenuProps['onClick'] = (e) => {
    localStorage.setItem(config.themeStorageKey, e.key);

    setTheme(e.key);
  };

  const handleLogOutClick: MenuProps['onClick'] = (e) => {
    localStorage.removeItem(config.apiToken);
    localStorage.removeItem(config.googleToken);

    setAuth({
      isLogged: false,
    });
  };

  const profileItems: MenuProps['items'] = [
    {
      key: 'theme',
      label: 'Theme',
      children: [
        {
          key: 'light',
          label: 'Light',
          onClick: handleThemeClick,
        },
        {
          key: 'dark',
          label: 'Dark',
          onClick: handleThemeClick,
        },
      ],
    },
    {
      icon: <LogoutOutlined />,
      label: 'Log out',
      key: 'logout',
      onClick: handleLogOutClick,
    },
  ];

  return (
    <Dropdown menu={{ items: profileItems }} trigger={['click']}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Avatar src={auth.image} style={{ marginRight: 10 }} />
        {!collapsed && (
          <AntdText style={{ color: '#ffff' }}>{auth.email}</AntdText>
        )}
      </div>
    </Dropdown>
  );
}

export default OfficeLayoutProfile;
